.sidebar {
    /* flex: 0.3; */
    width: 300px !important;
    padding: 0 15px;
}
.sidebar-mobile {
    flex: 1;
    min-width: 300px;
    padding: 1px 1px;
    background-color: white;
}

.sidebar-container-mobile {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.sidebar-compose {
    margin-top: 15px !important;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
    text-transform: capitalize !important;
    color: grey;
    padding: 15px 20px !important;
    border-radius: 15px !important;
    background-color: #c2e7ff !important;
}

.sidebar-compose:hover {
    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
    background-color: #c2e7ff;
}

.sidebar-footer {
    display: flex;
    justify-content: center;
}

.sidebar-link{
    text-decoration: none;
}

@media only screen and (max-width: 500px) {
    .sidebar-footerIcons > .MuiIconButton-root{
       padding: 1px !important;
    }

    .sidebar-compose{
        margin-left: 3px !important;
        padding: 10px 8px !important;
    }
}