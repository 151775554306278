.sidebarOption {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 10px;
    border-radius: 20px;
    /* border-bottom-right-radius: 20px; */
    cursor: pointer;
    color: #818181;
}

.sidebarOption--active,
.sidebarOption--active > p,
.sidebarOption--active > h3
 {
    background-color: #d3e3fd;
    color:#202124;
    font-weight: 560 !important;
}
.sidebarOption:hover,
.sidebarOption:hover > p,
.sidebarOption:hover > h3
{
    background-color: #e0e0e0;
}

.sidebarOption > h3 {
    flex: 1;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
}

.sidebarOption > p {
    display: inline;
    font-weight: 300;
    font-size: 12px;
}

.sidebarOption--active > p {
    display: inline !important;
}

/* .sidebarOption:hover > p {
    display: inline;
}

.sidebarOption > .MuiSvgIcon-root{
    padding: 5px;
} 

@media only screen and (max-width: 500px) {   
    .sidebarOption > .MuiSvgIcon-root{
        display: none;
    }
    
} */