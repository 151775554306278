/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* * {
  margin: 0; 
   overflow: hidden;
} */

@font-face {
  font-family: "Google Sans";
  src: url(https://www.cdnfonts.com/google-sans.font) format("truetype");
}

body {
  margin: 0;
  font-family: "Product Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 /* scroll-bar customizing */

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0;
}

::-webkit-scrollbar
{
width: 8px;
background-color: #F5F5F5;
}

::-webkit-scrollbar:hover
{
width: 12px;
}

::-webkit-scrollbar-thumb {
background-color: rgba(0,0,0,.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px;
  min-height: 28px;
  padding: 100px 0 0;
  -webkit-box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
  box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
}

::-webkit-scrollbar-corner {
  background: transparent;
}
  
