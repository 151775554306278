.emailList {
    grid-template-rows: auto auto 1fr;
    /* flex: 1; */
    overflow-y: auto;
    border-radius: 20px;
    background-color: white;
    margin-right:10px;
    margin-bottom: 10px !important;
}
.emailList-mobile {
    grid-template-rows: auto auto auto 1fr;
    flex: 1;
    overflow-y: auto;
    background-color: white;
}

.emailList-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid whitesmoke; */
    background-color: white;
    z-index: 99;
    padding-right: 5px;
    padding-left: 5px;
}

.emailList-settings-mobile {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid whitesmoke; */
    background-color: white;
    z-index: 99;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 3px;
}

.emailList-settings-mobile > .emailList-settingsLeft > .MuiButtonBase-root {
    margin-right: 10px;
}

.emailList-sections {
    position: sticky;
    top: 0;
    /* min-height: 50px; */
    display: flex;
    /* border-bottom: 1px solid whitesmoke;
    background-color: white; */
    z-index: 99;
}

.emailList-sections > .MuiButton-root {
    border-radius: 0px;
    padding: 0px;    
    align-items: end;
    text-transform: none;
}

.emailList-sections > .MuiButton-root:hover {
    background-color: whitesmoke;
}

.emailList-list {
    /* overflow-y: hidden !important; */
    padding-bottom: 10px;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.emalList::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.emailList {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}

.emailList-search-mobile {
    display: flex;
    align-items: center;
    flex: 0.7;
    background-color: #edeeef;
    padding: 2px 5px;
    margin: 8px;
    border-radius: 0px;
}

.emailList-search-mobile  .MuiSvgIcon-root {
    color: gray;
    font-size: 1.5rem !important;
}

.emailList-search-mobile > input {
    border: none;
    width: 100%;
    padding: 5px;
    outline: none;
    font-size: medium;
    background-color: transparent;
}

@media only screen and (max-width: 500px) {   
    
    .emailList-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .emailList-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .emailList-settingsLeft{
        margin-left: 5px;
    }

    .emailList-settingsRight{
        margin-right: 0px;
    }
}