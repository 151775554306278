.emailRow {
    display: flex;
    align-items: flex-start;
    padding: 9px 0px;
    height: auto; /* 40px; */
    border-bottom: 1px solid #e5e8eb !important;
    border-top: 1px solid #eceff1 !important;
    cursor: pointer;
    /* z-index: 999; */
    background-color: white;
    position: relative;
}
.emailRow-mobile {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 10px 0px;
    height: auto; /* 40px; */
    border-bottom: 1px solid #eceff1 !important;
    border-top: 1px solid #eceff1 !important;
    cursor: pointer;
    /* z-index: 999; */
    background-color: white;
    position: relative;
    width: 100%;    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.emailRow-selected {
    background-color: #c2dbff !important;
}

.emailRow-read {
    background-color: #f2f6fc;
}

.emailRow:hover {
    border-top: 1px solid #eceff1 !important;
    /* box-shadow: 0px 4px 4px -2px rgba(0,0,0,0.24); */
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    z-index: 2;
}

.emailRow:hover > .emailRow-options .MuiIconButton-label {
    color: #605f5f !important;
}

.emailRow-options {
    display: flex;
    padding-left: 5px;
}

.emailRow-options .MuiIconButton-label {
    color: #adadad !important;
}

.emailRow-message {
    display: flex; 
    flex-direction: column;
    /* align-items: center; */
    font-size: 14px;
    letter-spacing: normal;
    flex: 1 1;
    height: auto;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    margin-right: 30px;
}

.emailRow-message > .attachments {
    padding-top: 8px;
    display: flex;
}

.emailRow-message > .attachments > .attachment {
    border: 1px solid #b8b9b9;
    border-radius: 20px;
    margin-left: 10px;
    padding: 3px 10px;
    display: flex;
}

.emailRow-message > .attachments > .attachment > svg {
    height: 20px;
    margin: 0 5px;
    width: auto;
}

.file-name {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.emailRow-message > .content {
    /* width: 400px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
}

.emailRow-title{
    font-size: 14px;
    /* flex: 0.3; */
    font-weight: bold;
    flex-basis: 200px;
    max-width: 200px;
    padding-left: 0;
    padding-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.emailRow-title-mobile{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.emailRow-message-mobile {
    font-size: 14px;
    letter-spacing: normal;
    height: auto;

    /* start for text ellipsis */
    /* width: 200px; */
    display: block; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* start for text ellipsis */
    
    padding-left: 5px;
    margin-right: 30px;
}

.emailRow-action-button {
    padding-top: 0px !important; 
    padding-bottom: 0px !important; 
    background-color: transparent !important;
}

.emailRow-right-mobile {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center; 
}

.emailRow-attach-mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;    
    padding-right: 16px;
    color: grey;
}

.emailRow-time-mobile {
    padding-right: 15px;
    font-size: 12px;
    color: rgb(0, 115, 255);
    justify-content: center;
    padding-left: 0;
    padding-right: 16px;
}
.emailRow-time-read-mobile {
    color: gray;
}
.emailRow-time {
    padding-right: 15px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 16px;
}

.emailRow-subject {
    font-weight: bold;
    font-size: 14px;
}

.emailRow-subject-mobile {
    font-weight: bold;
    font-size: 17px;
}

.emailRow-main-read {
    font-weight: 400 !important;
    color:#202124;
}

.emailRow-description {
    font-weight: 400 !important;
    color:#5f6368;
}

@media only screen and (max-width: 700px) {   
    .emailRow-options > .MuiIconButton-root{
        padding-left: 2px;
        padding-right: 2px;
    }

    .emailRow-time {
        display: none;
    }
}