.file-download__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 555;
    left: 0px;
    top: 0px;
    right : 0px;
    bottom : 0px;
    border-radius : 3px;
    transition: all 0.5s ease;
}