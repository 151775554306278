.header{
    display: flex;
    align-items: center;
    /* justify-content: space-between;
    border-bottom: 1px solid whitesmoke; */
}

.header-left {
    display: flex;
    align-items: center;
    flex: 0.3;
    min-width: 300px;
    max-width: 300px;
    padding-left: 10px;
}

.header-left > img {
    object-fit: contain;
    height: 35px;
    margin-left: 5px;
}

.header-main {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.header-middle {
    display: flex;
    align-items: center;
    flex: 0.9;
    background-color: #eaf1fb;
    padding: 2px 5px;
    margin: 8px;
    border-radius: 30px;
    max-width: 800px;
}

.header-middle > .MuiSvgIcon-root {
    color: gray;
}

.header-middle > input {
    border: none;
    width: 100%;
    /* padding: 10px; */
    outline: none;
    font-size: medium;
    background-color: transparent;
}

.header-right{
    display: flex;
    padding-right: 20px;
    align-items: center;
}

.header-mobile {
    display: grid !important;
    grid-template-columns: 1fr 1fr auto;
    align-items: center;
    padding-left: 10px;
}

.header-compose {
    margin-top: 5px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
    text-transform: capitalize !important;
    min-width: 40px !important;
    color: white !important;
    padding: 5px 5px !important;
    border-radius: 5px !important;
    background-color: #f33434 !important;
}

/* @media only screen and (max-width: 500px) {
    .header-left > .MuiIconButton-root{
        display: none !important;
    }
  
    .header-middle {
        margin: 10px 0px;
    }  
    .header-right > .MuiIconButton-root{
        padding: 5px !important;
        display: none !important;
    }
} */