 .app{
    background-color: #f6f8fc;
}

.app-body{
    background-color: #f6f8fc;
    margin-bottom: 10px;
    overflow-x: hidden;
} 

.app-body .MuiSvgIcon-root {
    font-size: 1.2rem !important;
}
