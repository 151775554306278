.sendMail {
    z-index: 100;
    position: absolute;
    bottom: 0px;
    /* right: 60px;
    width: 35%;
    height: 66%;
    max-height: 80%; */
    right: 60px;
    width: 100%;
    max-width: 550px;
    min-width: 500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white !important;
    display: grid;
    grid-template-rows: auto 1fr;
    /* flex-direction: column; */
    border:  1px solid whitesmoke;
    /* box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.25); */
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
}

.cke_editable {
    line-height: 1.2 !important;
}

.cke_bottom {
    display: none !important;
}

.cke_resizer {
    display: none !important;
}

.cke_chrome {
    border: none !important;
}

.sendMail-parent {
    z-index: 99;
    position: relative;
    width: 100%;
    height: 100%;
}

.sendMail-black {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 70%;
    background-color: #000000;
}

.sendMail-header {
    padding: 10px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;
    background-color: #eaf1fb;
}

.sendMail-header-button {
    align-items: right;
}

.sendMail-header-button .MuiSvgIcon-root {
    font-size: 1.1rem !important;
}

.sendMail-header-button .MuiIconButton-root {
    padding: 4px !important;    
}

.sendMail-header > h3 {
    color: #041e49;
    font-size: 14px;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.sendMail-send {
    background-color: #3079ed !important;
    text-transform: capitalize !important;
    border-radius: 20px !important;
    margin: 5px 15px !important;
}

.sendMail-schedule-list {
    display: flex;
    color: dimgray;
}

.sendMail-schedule-date {
    z-index: 100;
    cursor: pointer;
    padding: 5px 10px;
    border: 0;
    border-radius: 5px;
    background-color: #216ba5;
    font: inherit;
    color: #fff;
}

.sendMail-schedule-pick {
    z-index: 100;
    position: absolute;
    width: 500px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white !important;
    display: grid;
    grid-template-rows: auto;
    border:  1px solid whitesmoke;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
}

.sendMail-close {
    cursor: pointer;
}

.sendMail > form {
    display: grid;
    /*grid-template-rows: auto auto 1fr auto;
    height: 480px;
    max-height: 600px;
    flex: 1;
    flex-direction: column; */
}

.sendMail-input-subject {
    font-family: "Product Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif !important;
}

.sendMail-form-div {
    display: grid;
    grid-auto-rows: auto 1fr;
    margin-bottom: 20px;
    overflow: auto;
}

.sendMail-content {
    display: grid;
    grid-template-rows: auto;
}

.sendMail-quill-editor {
    width: 100%;
    height: 100%;
}

.sendMail-to {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: end;
}

.sendMail-container-ccbcc {
    display: flex; 
    padding-bottom: 10px; 
    padding-right: 5px;
    border-bottom: 1px solid #eceff1;
}

.sendMail-ccbcc {
    margin-right: 5px;
    cursor: pointer;
    /* color: #888888; */
}

.sendMail-error {
    height: 30px;
    background-color: white;
    color:red;
    text-align: right;
    padding: 2px;
}

.sendMail-to-input {
    height: 33px;
    margin: 0 10px;
    padding-left: 4px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #eceff1;
    outline: none;
}

.sendMail-message {
    flex: 1;
    padding: 10px 18px;
    width: 100%;
    resize: none;
    border: none;
    outline: none;
}

.sendMail-options {
    position: relative;
    bottom: 5px;
    width: 100%;
}

.sendMail-options .MuiSvgIcon-root {
    font-size: 1.3rem !important;
}

.quill .ql-toolbar.ql-snow {
    border: 1px solid #eee !important;
    padding: 4px !important;
}

.quill .ql-container.ql-snow {
    border: 1px solid #eee !important;
    display: table !important;
    min-height: 320px;
}

.quill .ql-snow.ql-toolbar button {
    width: 24px !important;
    height: 22px !important;
}

.quill .ql-snow .ql-picker {
    font-size: 12px !important;
}

.quill .ap {
    display: contents !important;
}

@media only screen and (max-width: 300px) {   
    .sendMail {
        position: absolute;
        left:10%;
        width: 80%;
    }
  
}

/*//// for mobile ////////////*/

.sendMail-mobile {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white !important;
    display: grid;
    grid-template-rows: auto 1fr;
    border:  1px solid whitesmoke;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
}

.sendMail-header-mobile {
    padding: 10px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;
    background-color: #eaf1fb;    
}

.sendMail-header-mobile > h3 {
    color: #041e49;
    font-size: 14px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.sendMail-send-mobile {
    background-color: #3079ed !important;
    text-transform: capitalize !important;
    border-radius: 20px !important;
    margin: 5px 15px !important;
}

.sendMail-ccbcc-mobile {
    margin-right: 15px;
    cursor: pointer;
    /* color: #888888; */
}

.sendMail-close-mobile {
    cursor: pointer;
}

.sendMail-mobile > form {
    display: grid;
    grid-template-rows: auto;
    /* height: 500px;
    max-height: 700px;
    flex: 1;
    flex-direction: column; */
}

.sendMail-error-mobile {
    background-color: white;
    color:red;
    text-align: right;
    padding: 2px;
}

.sendMail-mobile > form > input {
    height: 30px;
    margin: 0 10px;
    padding-left: 4px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #eceff1;
    outline: none;
}

.sendMail-message-mobile {
    flex: 1;
    padding: 10px 18px;
    width: 100%;
    resize: none;
    border: none;
    outline: none;
}


@media only screen and (max-width: 300px) {   
    .sendMail-mobile {
        position: absolute;
        left:10%;
        width: 80%;
    }
  
}
