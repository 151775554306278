.mail {
    flex: 1;
    /* overflow-y: auto; */
    border-radius: 20px;
    background-color: white;
    margin-right:10px;
}
.mail-mobile {
    flex: 1;
    overflow-y: auto;
    background-color: white;
}

.mail-preview{
    /* word-break : break-all; */
    word-wrap : break-word;
    margin-left : 0px;
}

/* .mail-preview table {
    table-layout: fixed;
    width: 100%;
} */
  
.mail-preview * {
    /* word-break: break-all; */
    white-space: -moz-pre-wrap; /* Mozilla */
    white-space: -o-pre-wrap; /* Opera 7 */
    /* white-space: pre-wrap;  CSS 2.1 */
    /* white-space: pre-line; CSS 3 */
    word-wrap: break-word; /* IE */
    text-overflow: ellipsis;
}

.mail-preview td {
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}

.mail-preview blockquote{
    display:inline-block;
    word-wrap:break-word;
    white-space:break-spaces; 
}

.mail-preview pre{
/* word-break: break-all; */
white-space: -moz-normal; /* Mozilla */
white-space: -o-normal; /* Opera 7 */
white-space: normal;  /* CSS 2.1 */
white-space: normal; /* CSS 3 */
word-wrap: break-word; /* IE */
text-overflow: ellipsis;
}

.container {
    height: 100%;
}
  
.emailPlayground {
    display: flex;
    height: 90%;
    width: 95%;
    margin-inline: auto;
    gap: 8px;
}

.previewContainer {
    flex: 1;
    overflow: auto;
    padding: 4px;
}

.mail-pre {
    font-family: "Product Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif !important;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
}

.mail-pre > * {
    max-width: 100%;
    box-sizing: border-box;
}
  
.mail-pre p {
    margin: 5px 0 !important;
}

.mail-tools {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 20px;
}

.mail-toolsLeft {
    display: flex;
}

.mail-body {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    background-color: white;
    /* padding: 20px; */
    height: 100vh;
    /* box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.25); */
}

.mail-bodyHeader {
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid whitesmoke; */
    padding: 10px 20px 8px 72px;
    box-shadow: 0 3px 2px -2px rgba(0,0,0,.1);
    position: relative;
}

.mail-bodyHeader-mobile {
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid whitesmoke; */
    padding: 10px 20px 10px 20px;
    position: relative;
}

.mail-important {
    color: #e8ab02 !important;
}

.mail-bodyHeader > h2 {
    font-weight: 400;
    margin-right: 20px;
}

.mail-bodyHeader > p {
    margin-left: 20px;
}

.mail-time {
    /* position: absolute; */
    /* top: 24px; */
    /* right: 20px; */
    font-size: 12px;
    color: grey;
}

.mail-bodyHeader-right {
    position: absolute;
    /* top: 15px; */
    right: 0px;
    font-size: 12px;
    color: grey;
}

.mail-middle-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0px;
}

.mail-message > p {
    padding: 10px;
    margin-right: 20px;
    overflow-wrap: break-word;
}
.mail-subject {
    display: flex;
    font-size: 1.375rem;  
}

.mail-subject > h2 {
    font-weight: 400;
    font-size: 1.5rem;
    margin-block-start: 0em;
    margin-block-end: 0em;  
    max-width: 600px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mail-subject-mobile > h3 {
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;  
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mail-bodyContent {
    border-bottom: 1px solid #ebebeb;
    margin-right: 20px;
}

.mail-bodyContent-mobile {
    border-top: 1px solid #dadee2;
    margin-right: 0px;
}

.mail-attach-body {
    border-top: 1px solid #dadee2;
    padding-top:10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.mail-attach-body-mobile {
    border-top: 1px solid #dadee2;
    padding-top:10px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}

.mail-attachments {
    /* padding-top: 10px; */
    display: flex;
    flex-direction: row;
}

.mail-attachments > .mail-attachment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.mail-attachments > .mail-attachment .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0);
}

.mail-attachments > .mail-attachment svg {
    height: 150px;
    margin: 0 5px;
    width: auto;
}

.mail-attachments > .mail-attachment-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-left: -17px;
}

.mail-attachments > .mail-attachment-mobile .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0);
}

.mail-attachments > .mail-attachment-mobile svg {
    height: 50px;
    margin: 0 5px;
    width: auto;
}

.mail-file-name {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mail-reply-body {
    display: grid;
    grid-template-columns: auto 1fr;    
    padding-right: 0px;
}

.mail-send-reply {
    /* background-color: white !important; */
    display: flex;
    flex-direction: column;
    width: 100%;
    border:  1px solid rgb(202, 201, 201);
    border-radius: 16px;
    /* box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%); */
}

.mail-reply-receiver {
    padding: 15px 20px 0px 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    color: inherit;
}

.mail-reply-receiver-mobile {
    padding: 15px 20px 0px 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    color: inherit;
}

.mail-reply {
    text-transform: unset !important;
    border: solid 1px #abacac !important;
    border-radius: 18px !important;
    padding: 5px 15px !important;
}

.mail-sendMail-options {
    position: relative;
    bottom: 5px;
    width: 100%;
    /* padding: 3px 0;
    border-top: 1px solid rgb(230, 227, 227);
    display: grid;
    grid-template-columns: auto auto 1fr 15px; */
}

.quill .ql-container.ql-snow {
    min-height: 200px !important;
}

@media only screen and (max-width: 700px) {   
    .mail-bodyHeader{
        display: grid !important;
        margin-left: 0;
    }

    .mail-time {
        position: unset;
        margin-top: 5px;
    }
    
    .mail-bodyHeader > p{
        margin-left: 0;
    }
    .mail-message > p {
        margin-left: 10px;
    }
}

