.sidebarOption-mobile {
    display: flex;
    align-items: center;
    /* height: 46px; */
    padding: 12px 15px;
    border-radius: 0px;
    /* border-bottom: 1px solid #cac9c9; */
    cursor: pointer;
    color: #818181;
}

.sidebarOption-mobile--active,
.sidebarOption-mobile--active > p,
.sidebarOption-mobile--active > h3
 {
    background-color: white;
    color:#202124;
    font-weight: 560 !important;
    margin: 0px;
}
.sidebarOption-mobile:hover,
.sidebarOption-mobile:hover > p,
.sidebarOption-mobile:hover > h3
{
    background-color: #e0e0e0;
}

.sidebarOption-mobile > h3 {
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
}

.sidebarOption-mobile > p {
    display: inline;
    background-color: #818181;
    border: 1px solid #818181;
    border-radius: 3px;
    padding: 0px 12px;
    margin: 0px;
    color: white;
    font-weight: 300;
    font-size: 16px;
}

.sidebarOption-mobile--active > p {
    display: inline !important;
}

/* .sidebarOption:hover > p {
    display: inline;
}

.sidebarOption > .MuiSvgIcon-root{
    padding: 5px;
} 

@media only screen and (max-width: 500px) {   
    .sidebarOption > .MuiSvgIcon-root{
        display: none;
    }
    
} */